import request from '@/api/request'
import { message } from 'ant-design-vue'

/**
 * 应用列表
 *
 * @param params
 * @returns
 */
export const getProjects = (params: any) =>
  request.get('/api/v2/project/list', { params }).catch((err: any) => {
    err && message.error(err?.message)
  })

/**
 * 创建应用
 *
 * @param data
 * @returns
 */
export const postProjects = (data: any) =>
  request.post('/api/v2/project/create', data)

/**
 * 删除应用
 *
 * @param params
 * @returns
 */
export const deleteProject = (data: any) =>
  request.post('/api/v2/project/delete', data)

/**
 * 修改应用
 *
 * @param data
 * @returns
 */
export const patchProject = (data: any) =>
  request.post('/api/v2/project/edit', data)

/**
 * 应用详情
 *
 * @param params
 * @returns
 */
export const getProject = (params: any) =>
  request.get('/api/v2/project/info', { params })

/**
 * 刷新应用密钥
 *
 * @param data
 * @returns
 */
export const patchWebhookSecretKey = (data: any) =>
  request.post('/api/v2/project/refresh-webhook', data)

export const getLanguage = (params: any) =>
  request.get('/api/v2/base/language/all', { params })

export const getWebhookStatusOptions = (params: any) => {
    return request.get('/api/v2/base/webhook-event/status-pairs', { params })
}

export const getWebhookEventTypeOptions = (params: any) => {
    return request.get('/api/v2/base/webhook-event/type-pairs', { params })
}

export const getWebhookList = (params: any) => {
    return request.get('/api/v2/merchant/webhook-event/list', { params })
}

export const getWebhookDetail = (params: any) => {
    return request.get('/api/v2/merchant/webhook-event/detail', { params })
}

export const webhookRetry = (data: any) => {
    return request.post('/api/v2/merchant/webhook-event/resend', data)
}