import { LangName } from '@/config/cookieKeys'
import { defineStore } from "pinia"
import cookies from 'js-cookie'
import {getProject, getWebhookEventTypeOptions, getWebhookStatusOptions} from '@/api/project'
import {getLangIdName} from "@/utils/language";
import {LanguagesStore} from "@/stores/modules/languages";
import {cloneDeep} from "lodash-es";
import {getActivityItems, getAllGoods} from "@/api/activity";
import {getCurrencyList} from "@/api/currency";

export const ProjectStore = defineStore('project', {
  state: () => ({
    info: {} as any,
    projectId: '',
    projectNo: '',
    goodsList: [],
    loading: false,
    webhookStatusOptions: [],
    webhookEventTypeOptions: [],
    vCurrencyList: [],
    searchStorage: {
      bill: {},
      business: {}
    } as any
  }),

  getters: {
    storeLang(state) {
      const { default_language, languages } = state.info;

      // zh 需转成 cn
      // eslint-disable-next-line
      const lang = cookies.get(LangName) === 'zh' ? 'cn' : cookies.get(LangName);
      // 网站语言包括在商品语言中，商品跟随网站语言；反之商品显示商品默认语言
      if (languages && languages.includes(lang)) {
        return lang
      } else {
        // eslint-disable-next-line
        return default_language;
      }
    }
  },

  actions: {
    clearSearchStorageItem(key: string) {
      if (!key) return
      this.searchStorage[key] = {}
    },
    setSearchStorageItem(bucket: any, data: object) {
      this.searchStorage[bucket] = cloneDeep(data)
    },
    getSearchStorageItem(bucket: string, target: any) {
      const origin = this.searchStorage[bucket]
      for (const key in target) {
        if (origin[key]) {
          target[key] = origin[key]
        }
      }
    },
    SET_INFO(info: any) {
      this.info = info
    },
    async getWebhookStatusList(params: any) {
      const res = await getWebhookStatusOptions(params)
      if (res) {
        this.webhookStatusOptions = res
      }
    },
    async getWebhookEventTypeList(params: any) {
      const res = await getWebhookEventTypeOptions(params)
      if (res) {
        this.webhookEventTypeOptions = res
      }
    },
    getCurrencyList() {
      getCurrencyList({
        merchant_id: this.info.merchant_id,
        project_id: this.info.project_id,
        pageSize: 10000,
        pageNo: 1
      }).then((res: any) => {
        this.vCurrencyList = res.data
      }).catch(() => {
        this.vCurrencyList = []
      })
    },
    async getGoodsList(params: any, noFilter: any) {
      this.goodsList = []
      const languageStore = LanguagesStore()
      this.loading = true
      const res = noFilter ? await getAllGoods(params) : await getActivityItems(params)
      this.loading = false
      if (res?.list) {
        return this.goodsList = res.list.map((item: any) => {
          return {
            label: getLangIdName(item.goods_name, this.storeLang, languageStore.langIdData),
            value: Number(item.goods_id),
            logo: item.goods_logo,
            code: item.goods_code
          }
        })
      }
    },
    SET_PROJECT_ID(id: string) {
      this.projectId = id
    },
    SET_PROJECT_NO(id: string) {
      this.projectNo = id
    },
    async updateProject(payload: any) {
      const res = await getProject({
        project_id: payload.id
      })
      this.SET_INFO(res)
    }
  }
});
